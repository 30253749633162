<template>
  <div class="webapplication">
    <div class="first_view">
      <div class="title">
        <h3 class="primary">
          {{ $t('webapplication.first_view.title') }}
        </h3>
      </div>
      <div class="subtitle">
        <p>{{ $t('webapplication.first_view.subtitle') }}</p>
      </div>
    </div>
    <div class="second_view">
      <div class="title">
        <h3 class="dark">
          {{ $t('webapplication.second_view.title') }}
        </h3>
      </div>
      <div class="text">
        <p class="dark">
          {{ $t('webapplication.second_view.text') }}
        </p>
      </div>
      <i class="fas fa-tools" />
    </div>
    <div class="third_view">
      <div class="title">
        <h3>{{ $t('webapplication.third_view.title') }}</h3>
      </div>
      <div class="text">
        <p>{{ $t('webapplication.third_view.text') }}</p>
      </div>
      <i class="fas fa-fighter-jet" />
    </div>
    <div class="fourth_view contact_form_container">
      <ContactForm />
    </div>
  </div>
</template>
<script>
import ContactForm from '../../components/ContactForm.vue'

export default {
  name: 'WebApplication',
  components: {
    ContactForm,
  },
  metaInfo() {
    const locale = this.locale
    return {
      title: this.$i18n.t('webapplication.title'),
      titleTemplate: null,
      meta: [
        {
          name: 'description',
          content:
            locale === 'fr'
              ? "L'Agence Web Beautec utilise des technologies modernes et rapides. Nous créons des sites internet et applications web qui font fière allure. Nous nous concentrons sur les entreprises qui veulent faire une différence pour l'environnement et internet."
              : 'Beautec uses modern and fast technologies. We create websites and web applications that look great. We focus on companies that want to make a difference for the environment and the Internet.',
        },
        { name: 'image', content: 'https://cdn.beautec.ca/images/website/first_view_web_application.jpg' },
        // OpenGraph data (Most widely used)
        {
          property: 'og:title',
          content:
            locale === 'fr'
              ? "Création d'Application Web | Beautec - Agence Web technologique moderne - Rimouski"
              : 'Web Application Creation Service | Beautec - Modern Technological Web Agency - Rimouski',
        },
        { property: 'og:site_name', content: 'Beautec' },
        { property: 'og:type', content: 'website' },
        {
          property: 'og:url',
          content:
            locale === 'fr'
              ? 'https://beautec.ca/fr/products/web-application'
              : 'https://beautec.ca/en/products/web-application',
        },
        {
          property: 'og:image',
          content: 'https://cdn.beautec.ca/images/website/first_view_web_application.jpg',
        },
        {
          property: 'og:description',
          content:
            locale === 'fr'
              ? "L'Agence Web Beautec utilise des technologies modernes et rapides. Nous créons des sites internet et applications web qui font fière allure. Nous nous concentrons sur les entreprises qui veulent faire une différence pour l'environnement et internet."
              : 'Beautec uses modern and fast technologies. We create websites and web applications that look great. We focus on companies that want to make a difference for the environment and the Internet.',
        },

        // Twitter card
        {
          property: 'twitter:title',
          content:
            locale === 'fr'
              ? "Création d'Application Web | Beautec - Agence Web technologique moderne - Rimouski"
              : 'Web Application Creation Service | Beautec - Modern Technological Web Agency - Rimouski',
        },
        { property: 'twitter:card', content: 'summary' },
        {
          property: 'twitter:site',
          content:
            locale === 'fr'
              ? 'https://beautec.ca/fr/products/web-application'
              : 'https://beautec.ca/en/products/web-application',
        },
        { property: 'twitter:creator', content: '@squaretymedia' },
        {
          property: 'twitter:image:src',
          content: 'https://cdn.beautec.ca/images/website/first_view_web_application.jpg',
        },
        {
          property: 'twitter:description',
          content:
            locale === 'fr'
              ? "L'Agence Web Beautec utilise des technologies modernes et rapides. Nous créons des sites internet et applications web qui font fière allure. Nous nous concentrons sur les entreprises qui veulent faire une différence pour l'environnement et internet."
              : 'Beautec uses modern and fast technologies. We create websites and web applications that look great. We focus on companies that want to make a difference for the environment and the Internet.',
        },

        // Google / Schema.org markup:
        {
          itemprop: 'name',
          content:
            locale === 'fr'
              ? "Création d'Application Web | Beautec - Agence Web technologique moderne - Rimouski"
              : 'Web Application Creation Service | Beautec - Modern Technological Web Agency - Rimouski',
        },
        {
          itemprop: 'description',
          content:
            locale === 'fr'
              ? "L'Agence Web Beautec utilise des technologies modernes et rapides. Nous créons des sites internet et applications web qui font fière allure. Nous nous concentrons sur les entreprises qui veulent faire une différence pour l'environnement et internet."
              : 'Beautec uses modern and fast technologies. We create websites and web applications that look great. We focus on companies that want to make a difference for the environment and the Internet.',
        },
        { itemprop: 'image', content: 'https://cdn.beautec.ca/images/website/first_view_web_application.jpg' },
      ],
      link: [
        locale === 'fr'
          ? { rel: 'canonical', href: 'https://beautec.ca/fr/products/web-application' }
          : { rel: 'canonical', href: 'https://beautec.ca/en/products/web-application' },
      ],
    }
  },
}
</script>
<style scoped src="@/assets/css/services.less" lang="less"></style>

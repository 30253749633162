<template>
  <div class="tos">
    <div v-if="lang == 'fr'" id="fr">
      <div class="_s1 col-pc-12">
        <div class="flexbox">
          <div class="_content col-pc-10">
            <div class="_title">
              <h3>Conditions générales d'utilisation</h3>
            </div>
            <div class="_text-container">
              <div class="legal-application">
                <ul class="last-update">
                  <li class="text" style="margin-right: 5px">
                    Dernière modification:
                  </li>
                  <li class="date">
                    <p>15 Décembre 2019</p>
                  </li>
                </ul>
                <ul class="effective">
                  <li class="text" style="margin-right: 5px">
                    Entrée en vigueur: &nbsp;
                  </li>
                  <li class="date">
                    <p>18 Décembre 2019</p>
                  </li>
                </ul>
              </div>
              <div class="legal-content">
                <div class="__official-lang-application">
                  <i>
                    Cette traduction est fournie uniquement pour des raisons de commodité et la version anglaise
                    prévaudra en cas de divergence.
                  </i>
                </div>
                <h3>1. Conditions</h3>
                <p>
                  En accédant au site Web à partir de <a href="https://squaretymedia.com">https://squaretymedia.com</a>,
                  l’application Web à partir de
                  <a href="https://app.squaretymedia.com">https://app.squaretymedia.com</a> ou l’API, vous acceptez
                  d’être lié par les présentes conditions de service, toutes les lois et tous les règlements
                  applicables, et vous acceptez d’être responsable du respect des lois locales applicables. Si vous
                  n’acceptez aucun de ces termes, il vous est interdit d’utiliser ou d’accéder à ce site, à cette
                  application Web et à l’API. Les documents contenus dans ce site Web, dans cette application Web et cet
                  API sont protégés par le droit d’auteur et le droit des marques de commerce applicables.
                </p>

                <h3>2. License d'utilisation</h3>
                <p>
                  La permission est accordée de télécharger temporairement un exemplaire du matériel (information ou
                  logiciel) sur le site Web, l’application Web et l’API de Squarety Média pour une consultation
                  transitoire personnelle et non commerciale seulement. Il s’agit de l’octroi d’une licence, et non d’un
                  transfert de titre, <br />et en vertu de cette licence, vous ne pouvez pas:
                </p>
                <p class="__article">
                  &#10163; modifier ou copier les documents;<br />&#10163; utiliser le matériel à des fins commerciales
                  ou pour un affichage public (commercial ou non commercial);<br />&#10163; tenter de décompiler ou de
                  d’effectuer de la rétro-ingénierie sur tout contenu logiciel sur le site Web, l’application Web ou
                  l’API de Squarety Média;<br />&#10163; supprimer les droits d’auteur ou autres notations exclusives du
                  matériel;<br />&#10163; transférer les données et le contenu à une autre personne ou copier les
                  données et le contenu sur tout autre serveur;
                </p>
                <p>
                  Cette licence prend fin automatiquement si vous enfreignez l’une de ces restrictions et peut être
                  résiliée par Squarety Média à tout moment. Au moment de mettre fin à votre visionnement de ces
                  documents ou à la résiliation de cette licence, vous devez détruire tout matériel téléchargé en votre
                  possession, qu’il soit en format électronique ou imprimé.
                </p>

                <h3>3. Avertissement de responsabilité</h3>
                <p>
                  Les documents figurant sur le site Web, l’application Web et l’API de Squarety Média sont fournis «
                  tels quels ». Squarety Média ne donne aucune garantie, expresse ou implicite, et décline et nie par
                  les présentes toutes les autres garanties, y compris, sans limitation, les garanties implicites ou les
                  conditions de qualité marchande, l’aptitude à une fin particulière, ou la non-violation de la
                  propriété intellectuelle ou d’autres violations des droits.
                </p>
                <p>
                  En outre, Squarety Média ne garantit ni ne fait d’observations concernant l’exactitude, les résultats
                  probables ou la fiabilité de l’utilisation des documents sur son site Web, son application Web et son
                  API publiés par l’utilisateur ni ne se rapporte d’une autre manière à ces documents ou à tout site lié
                  à ce site Web, cette application Web et cet API.
                </p>

                <h3>4. Limites</h3>
                <p>
                  En aucun cas Squarety Média ou ses fournisseurs ne seront tenus responsables des dommages (y compris,
                  sans s’y limiter, des dommages pour perte de données ou de profits, ou des interruptions d’activités)
                  résultant de l’utilisation ou de l’incapacité d’utiliser le matériel sur le site Web, l’application
                  Web ou l’API de Squarety Média même si Squarety Média ou un représentant autorisé de Squarety Média a
                  été avisé oralement ou par écrit de la possibilité de tels dommages. Étant donné que certaines
                  juridictions n’autorisent pas de limitations quant aux garanties implicites ou aux limites de
                  responsabilité en cas de dommages corrélatifs ou accidentels, ces limitations peuvent ne pas
                  s’appliquer à vous.
                </p>

                <h3>5. Cookies</h3>
                <div class="__article">
                  <h4>5.1. Qu'est-ce que des Cookies</h4>
                  <p>
                    Comme c’est la pratique courante sur presque tous les sites Web professionnels, ce site Web, cette
                    application Web et cet API, utilise des cookies, qui sont de minuscules fichiers qui sont
                    téléchargés sur votre ordinateur, pour améliorer votre expérience. Cette section décrit les
                    informations qu’ils recueillent, comment Squarety Média les utilisent et pourquoi Squarety Média a
                    parfois besoin de stocker ces cookies. Squarety Média partagera également la façon dont vous pouvez
                    empêcher ces cookies d’être stockés, mais cela peut réduire ou ”briser” certaines fonctionnalités du
                    site Web, de l’application Web et de l’API.<br />
                    Pour plus d’informations générales sur les cookies, voir l’article de Wikipedia sur les cookies
                    HTTP.
                  </p>

                  <h4>5.2. Comment Squarety Média utilise les Cookies</h4>
                  <p>
                    Squarety Média utilise des cookies pour diverses raisons décrites ci-dessous. Dans la plupart des
                    cas, il n’y a pas d’options standard de l’industrie pour désactiver les cookies sans complètement
                    désactiver les fonctionnalités qu’ils ajoutent à ce site Web, cette application Web et cet API. Il
                    est recommandé de laisser tous les cookies si vous n’êtes pas sûr si vous en avez besoin ou non dans
                    le cas où ils sont utilisés pour fournir un service que vous utilisez.
                  </p>

                  <h4>5.3. Désactivation des Cookies</h4>
                  <p>
                    Vous pouvez empêcher le réglage des cookies en ajustant les paramètres de votre navigateur (voir
                    Aide de votre navigateur pour savoir comment faire). Soyez conscient que la désactivation des
                    cookies aura une influence sur les fonctionnalités de ce site Web, cette application Web et cet API.
                    Désactiver les cookies se traduira généralement par la désactivation de certaines fonctionnalités de
                    ce site. Par conséquent, <b>il est recommandé de ne pas désactiver les cookies.</b>
                  </p>

                  <h4>5.4. Les Cookies utilisés par Squarety Média</h4>
                  <div class="__subarticle">
                    <h5>Cookies reliés à l’ouverture de session</h5>
                    <p>
                      Squarety Média utilise des cookies lorsque vous êtes connecté afin que Squarety Média puisse se
                      souvenir de ce fait. Cela vous empêche d’avoir à vous connecter chaque fois que vous visitez une
                      nouvelle page. Ces cookies sont habituellement supprimés ou compensés lorsque vous vous connectez
                      pour vous assurer que vous ne pouvez accéder qu’aux fonctions et aux zones restreintes lorsque
                      vous vous connectez.
                    </p>
                  </div>
                  <div class="__subarticle">
                    <h5>Cookies reliés au traitement des achats</h5>
                    <p>
                      Ce site Web, cette application Web et cet API offrent des possibilités de e-commerce ou de
                      paiement et certains cookies sont essentiels pour s’assurer que votre commande est mémorisée entre
                      les pages afin que Squarety Média puisse traiter la commande correctement.
                    </p>
                  </div>
                  <div class="__subarticle">
                    <h5>Cookies reliés aux formulaires</h5>
                    <p>
                      Lorsque vous soumettez des données à l’aide d’un formulaire, les cookies peuvent être configurés
                      pour se souvenir de vos coordonnées d’utilisateur pour des correspondances futures.
                    </p>
                  </div>

                  <h4>5.5. Cookies de tiers</h4>
                  <p>
                    Dans certains cas particuliers, Squarety Média utilise également des cookies fournis par des tiers
                    de confiance. Le point suivant détaille les cookies tiers que vous pourriez rencontrer sur ce site
                    Web, cette application Web et cet API.
                  </p>
                  <div class="__subarticle">
                    <p>
                      Ce site Web, cette application Web et cet API utilisent Google Analytics qui est l’une des
                      solutions d’analyse les plus répandues et fiables sur le Web pour aider Squarety Média à
                      comprendre comment vous utilisez le site Web, l’application Web et l’API et comment Squarety Média
                      peut améliorer votre expérience. Ces cookies peuvent suivre des choses telles que le temps que
                      vous passez sur le site et les pages que vous visitez afin que Squarety Média puisse continuer à
                      produire du contenu captivant.
                    </p>
                    <p>
                      Pour plus d’informations sur les cookies Google Analytics, consultez la page officielle de Google
                      Analytics.
                    </p>
                  </div>

                  <h4>5.6. Plus d’informations</h4>
                  <p>
                    Espérons que cela a clarifié les choses pour vous et comme Squarety Média l’a déjà mentionné s’il y
                    a quelque chose dont vous n’êtes pas sûr si vous avez besoin ou non, il est généralement plus sûr de
                    laisser les cookies activés au cas où il interagit avec l’une des fonctionnalités que vous utilisez
                    sur ce site Web, cette application Web et cet API. Cependant, si vous cherchez toujours plus
                    d’informations, vous pouvez contacter Squarety Média en écrivant à l’adresse courriel
                    <a href="mailto:contact@squaretymedia.com">contact@squaretymedia.com</a>.
                  </p>
                </div>
                <h3>6. Suspension et Terminaison de Services</h3>
                <div class="__article">
                  <h4>6.1. Par Vous.</h4>
                  <p>
                    Vous pouvez terminer votre Abonnement à tout moment depuis votre page de gestion de compte. Une
                    telle terminaison aboutira à la désactivation ou à la mise hors de service de votre espace
                    communautaire et l’accès de celui-ci puis l'effacement du contenu que vous avez publié en utilisant
                    les Services dans un délai allant jusqu’à 6 mois depuis l'annulation. Les résiliations sont
                    confirmées immédiatement et vous ne serez pas facturé de nouveau pour cet abonnement à moins que
                    vous n’annulez votre demande de résiliation ou vous achetez un nouvel abonnement. Si vous terminez
                    un Abonnement au milieu d'un cycle de facturation, vous ne recevrez aucun remboursement pour la
                    période de temps que vous n'avez pas utilisé dans ce cycle de facturation et vous aurez accès à
                    votre espace communautaire pour la période de temps restante seulement.
                  </p>
                  <h4>6.2. Par Squarety Média.</h4>
                  <p>
                    Squarety Média peut mettre fin à votre Abonnement en tout temps si vous ne payez pas les frais
                    pendant 30 jours après la date d’échéance. De plus, Squarety Média peut limiter, suspendre, ou
                    terminer les Services: (i) si vous échouez à respecter ces Termes, (ii) si vous utilisez les
                    Services d’une façon qui cause à Squarety Média la responsabilité légale ou perturbe l'utilisation
                    d'autres utilisateurs des Services; (iii) si nous sommes dans un processus d’examen pour une ou des
                    fautes soupçonnées que vous avez possiblement commises; (iiii) si vous commettez une fraude. Aussi,
                    si nous limitons, suspendons, ou terminons les Services que vous recevez, nous nous efforcerons de
                    vous donner le préavis. Cependant, il peut y avoir des situations sensibles où Squarety Média peut
                    décider le besoin de prendre l'action immédiate sans avertissement. Squarety Média peut également
                    limiter, suspendre ou terminer les Services de tous utilisateurs sans devoir mentionner la raison
                    précise.
                  </p>
                  <h4>6.3 Inactivité de Compte.</h4>
                  <p>
                    Squarety Média peut fermer votre compte et y supprimer tout son contenu s'il n'y a aucune activité
                    du compte (l’activité peut-être une connexion au compte ou par exemple, un paiement) pendant plus de
                    12 mois. Cependant, Squarety Média essayera de vous avertir par courrier électronique avant de
                    fermer votre compte pour vous fournir une occasion de vous connecter à votre compte pour que
                    celui-ci reste actif.
                  </p>
                </div>
                <h3>7. Précision des données</h3>
                <p>
                  Le matériel figurant sur le site Web et l’application Web de Squarety Média pourrait comprendre des
                  erreurs techniques, typographiques ou photographiques. Squarety Média ne garantit pas que les
                  documents de son site Web et application Web sont exacts, complets ou à jour. Squarety Média peut
                  apporter sans préavis des modifications aux documents contenus sur son site Web et application Web.
                  Toutefois, Squarety Média ne s’engage pas à mettre à jour les documents.
                </p>
                <h3>8. Liens</h3>
                <p>
                  Squarety Média n’a pas examiné tous les sites liés à son site Web, application Web et API et n’est pas
                  responsable du contenu de ces sites. L’inclusion de tout hyperlien n’implique pas l’approbation par
                  Squarety Média. L’utilisation d’un hyperlien est à vos propres risques.
                </p>
                <h3>9. Modifications</h3>
                <p>
                  Squarety Média peut réviser ces conditions de service pour son site Web, application Web ou API à tout
                  moment sans préavis. En utilisant ce site Web, cette application Web ou cet API vous acceptez d’être
                  lié par la version actuelle de ces conditions de service.
                </p>
                <h3>10. Droit applicable</h3>
                <p>
                  Ces modalités et conditions sont régies et interprétées conformément aux lois canadiennes et vous vous
                  soumettez irrévocablement à la compétence exclusive des tribunaux de Rimouski, Québec, Canada.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="lang == 'en'" id="en">
      <div class="_s1 col-pc-12">
        <div class="flexbox">
          <div class="_content col-pc-10">
            <div class="_title">
              <h3>Terms of Service</h3>
            </div>
            <div class="_text-container">
              <div class="legal-application">
                <ul class="last-update">
                  <li class="text" style="margin-right: 5px">
                    Last update: &nbsp;
                  </li>
                  <li class="date">
                    <p>15 December 2019</p>
                  </li>
                </ul>
                <ul class="effective">
                  <li class="text" style="margin-right: 5px">
                    Effective: &nbsp;
                  </li>
                  <li class="date">
                    <p>18 December 2019</p>
                  </li>
                </ul>
              </div>
              <div class="legal-content">
                <div class="__official-lang-application">
                  <!--<i>This translation is provided for convenience only and the English language version will control in the event of any discrepancies.</i>-->
                </div>
                <h3>1. Terms</h3>
                <p>
                  By accessing the website at <a href="https://squaretymedia.com">https://squaretymedia.com</a>, the web
                  application at <a href="https://app.squaretymedia.com">https://app.squaretymedia.com</a> or the API,
                  you are agreeing to be bound by these terms of service, all applicable laws and regulations, and agree
                  that you are responsible for compliance with any applicable local laws. If you do not agree with any
                  of these terms, you are prohibited from using or accessing this website, web application or API. The
                  materials contained in this website, web application and API are protected by applicable copyright and
                  trademark law.
                </p>

                <h3>2. Use License</h3>
                <p>
                  Permission is granted to temporarily download one copy of the materials (information or software) on
                  Squarety Média's website, web application and API for personal, non-commercial transitory viewing
                  only. This is the grant of a license, not a transfer of title, and under this license you may not:
                </p>
                <p class="__article">
                  &#10163; modify or copy the materials;<br />&#10163; use the materials for any commercial purpose, or
                  for any public display (commercial or non-commercial);<br />&#10163; attempt to decompile or reverse
                  engineer any software contained on Squarety Média's website, web application or API;<br />&#10163;
                  remove any copyright or other proprietary notations from the materials;<br />&#10163; or transfer the
                  materials to another person or "mirror" the materials on any other server;
                </p>
                <p>
                  This license shall automatically terminate if you violate any of these restrictions and may be
                  terminated by Squarety Média at any time. Upon terminating your viewing of these materials or upon the
                  termination of this license, you must destroy any downloaded materials in your possession whether in
                  electronic or printed format.
                </p>

                <h3>3. Disclaimer</h3>
                <p>
                  The materials on Squarety Média's website, web application and API are provided on an 'as is' basis.
                  Squarety Média makes no warranties, expressed or implied, and hereby disclaims and negates all other
                  warranties including, without limitation, implied warranties or conditions of merchantability, fitness
                  for a particular purpose, or non-infringement of intellectual property or other violation of rights.
                </p>
                <p>
                  Further, Squarety Média does not warrant or make any representations concerning the accuracy, likely
                  results, or reliability of the use of the materials on its website, web application and API or
                  otherwise relating to such materials or on any sites linked to this website, web application and API.
                </p>

                <h3>4. Limitations</h3>
                <p>
                  In no event shall Squarety Média or its suppliers be liable for any damages (including, without
                  limitation, damages for loss of data or profit, or due to business interruption) arising out of the
                  use or inability to use the materials on Squarety Média's website, web application or API, even if
                  Squarety Média or a Squarety Média authorized representative has been notified orally or in writing of
                  the possibility of such damage. Because some jurisdictions do not allow limitations on implied
                  warranties, or limitations of liability for consequential or incidental damages, these limitations may
                  not apply to you.
                </p>

                <h3>5. Cookies</h3>
                <div class="__article">
                  <h4>5.1. What Are Cookies</h4>
                  <p>
                    As is common practice with almost all professional websites this website, web application and API,
                    uses cookies, which are tiny files that are downloaded to your computer, to improve your experience.
                    This page describes what information they gather, how Squarety Média use it and why Squarety Média
                    sometimes need to store these cookies. Squarety Média will also share how you can prevent these
                    cookies from being stored however this may downgrade or 'break' certain elements of the sites, web
                    applications and API functionalities.
                  </p>

                  <h4>5.2. How We Use Cookies</h4>
                  <p>
                    Squarety Média use cookies for a variety of reasons detailed below. In most cases there are no
                    industry standard options for disabling cookies without completely disabling the functionality and
                    features they add to this website, web application and API. It is recommended that you leave on all
                    cookies if you are not sure whether you need them or not in case they are used to provide a service
                    that you use.
                  </p>

                  <h4>5.3. Disabling Cookies</h4>
                  <p>
                    You can prevent the setting of cookies by adjusting the settings on your browser (see your browser
                    Help for how to do this). Be aware that disabling cookies will affect the functionalities of this
                    website, web application and API. Disabling cookies will usually result in also disabling certain
                    functionality and features of the website, web application and API. Therefore it is recommended that
                    you do not disable cookies.
                  </p>

                  <h4>5.4. The Cookies We Set</h4>
                  <div class="__subarticle">
                    <h4>Login related cookies</h4>
                    <p>
                      Squarety Média use cookies when you are logged in so that Squarety Média can remember this fact.
                      This prevents you from having to log in every single time you visit a new page. These cookies are
                      typically removed or cleared when you log out to ensure that you can only access restricted
                      features and areas when logged in.
                    </p>
                  </div>
                  <div class="__subarticle">
                    <h4>Orders processing related cookies</h4>
                    <p>
                      This website, web application and API offers e-commerce or payment facilities and some cookies are
                      essential to ensure that your order is remembered between pages so that Squarety Média can process
                      it properly.
                    </p>
                  </div>
                  <div class="__subarticle">
                    <h4>Forms related cookies</h4>
                    <p>
                      When you submit data to through a form cookies may be set to remember your user details for future
                      correspondences.
                    </p>
                  </div>

                  <h4>5.5. Third Party Cookies</h4>
                  <p>
                    In some special cases Squarety Média also use cookies provided by trusted third parties. The
                    following section details which third party cookies you might encounter through this website, web
                    application and API.
                  </p>
                  <div class="__subarticle">
                    <p>
                      This website, web application and API uses Google Analytics which is one of the most widespread
                      and trusted analytics solution on the web for helping Squarety Média to understand how you use the
                      website, web application and API and ways that we can improve your experience. These cookies may
                      track things such as how long you spend on the site and the pages that you visit so Squarety Média
                      can continue to produce engaging content.
                    </p>
                    <p>For more information on Google Analytics cookies, see the official Google Analytics page.</p>
                  </div>

                  <h4>5.6. More Information</h4>
                  <p>
                    Hopefully that has clarified things for you and as was previously mentioned if there is something
                    that you aren't sure whether you need or not it's usually safer to leave cookies enabled in case it
                    does interact with one of the features you use on Squarety Média’s website, web application or API.
                    However if you are still looking for more information then you can contact Squarety Média by writing
                    to the email address <a href="mailto:contact@squaretymedia.com">contact@squaretymedia.com</a>.
                  </p>
                </div>
                <h3>6. Suspension and Termination of Services</h3>
                <div class="__article">
                  <h4>6.1. By You.</h4>
                  <p>
                    You can terminate your Subscription at any time through your account management page. Such
                    termination will result in the desactivation or disablement of your community space and access to
                    it, and the deletion of content you collected through use of the Services within 6 months of the
                    cancellation. Terminations are confirmed immediately and you will not be charged again for that
                    Subscription unless you cancel your demand of termination or buy a new subscription. If you
                    terminate a Subscription in the middle of a billing cycle, you will not receive a refund for any
                    period of time you did not use in that billing cycle and you will have access to your community
                    space for the remaining period of time only.
                  </p>
                  <h4>6.2. By Squarety Média.</h4>
                  <p>
                    Squarety Média may terminate your Subscription at any given time if you fail to pay fees for 30 days
                    past the due date. Additionally, Squarety Média may limit, suspend, or terminate the Services to
                    you: (i) if you fail to comply with these Terms, (ii) if you use the Services in a way that causes
                    legal liability to Squarety Média or disrupts others’ use of the Services; or (iii) if we are
                    investigating suspected misconduct by you; (iiii) if you commit a fraud. Also, if we limit, suspend,
                    or terminate the Services you receive, we will endeavor to give you advance notice. However, there
                    may be time sensitive situations where Squarety Média may decide the need to take immediate action
                    without notice. Squarety Média may also limit, suspend or terminate the Services of any user without
                    having to mention the specific reason.
                  </p>
                  <h4>6.3 Account Inactivity.</h4>
                  <p>
                    Squarety Média may terminate your account and delete any content contained in it if there is no
                    account activity (such as a log in event or payment) for over 12 months. However, Squarety Média
                    will attempt to warn you by email before terminating your account to provide you with an opportunity
                    to log in to your account so that it remains active.
                  </p>
                </div>
                <h3>7. Accuracy of materials</h3>
                <p>
                  The materials appearing on Squarety Média's website, web application and API could include technical,
                  typographical, or photographic errors. Squarety Média does not warrant that any of the materials on
                  its website, web application and API are accurate, complete or current. Squarety Média may make
                  changes to the materials contained on its website, web application or API at any time without notice.
                  However Squarety Média does not make any commitment to update the materials.
                </p>
                <h3>8. Links</h3>
                <p>
                  Squarety Média has not reviewed all of the sites linked to its website, web application and API and is
                  not responsible for the contents of any such linked site. The inclusion of any link does not imply
                  endorsement by Squarety Média. Use of any such linked website is at the user's own risk.
                </p>
                <h3>9. Modifications</h3>
                <p>
                  Squarety Média may revise these terms of service for its website, web application or API at any time
                  without notice. By using this website, web application ou API you are agreeing to be bound by the then
                  current version of these terms of service.
                </p>
                <h3>10. Governing Law</h3>
                <p>
                  These terms and conditions are governed by and construed in accordance with the canadian laws and you
                  irrevocably submit to the exclusive jurisdiction of the courts in Rimouski, Québec, Canada.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'TermsOfService',
  computed: {
    lang() {
      return this.$route.params.lang
    },
  },
}
</script>
<style scoped src="@/assets/css/legal.less" lang="less"></style>

<style>
a {
  transition: all 0.3s;
}
a:hover {
  color: #f2684a;
  transition: all 0.3s;
}
</style>

<template>
  <div class="tos">
    <div class="first_view">
      <h2>{{ $t('terms_of_sale') }}</h2>
      <div class="button">
        <a href="//cdn.squaretymedia.com/public-documents/cgv-july-2020.pdf" download>
          <button type="button" class="secondary-full">
            <h3>{{ $t('download_pdf') }}</h3>
          </button>
        </a>
      </div>
      <iframe src="//cdn.squaretymedia.com/public-documents/cgv-july-2020.pdf" title="CGV-JULY-2020" frameborder="0" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'TermsOfSale',
}
</script>
<style scoped src="@/assets/css/legal.less" lang="less"></style>

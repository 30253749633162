import { render, staticRenderFns } from "./LanguageSelector.vue?vue&type=template&id=01d83f82&scoped=true&"
import script from "./LanguageSelector.vue?vue&type=script&lang=js&"
export * from "./LanguageSelector.vue?vue&type=script&lang=js&"
import style0 from "@/assets/css/components/language-selector.less?vue&type=style&index=0&id=01d83f82&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01d83f82",
  null
  
)

export default component.exports
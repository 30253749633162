<template>
  <div class="default">
    <DefaultNavbar />
    <router-view />
    <DefaultFooter />
  </div>
</template>
<script>
import DefaultNavbar from '../components/DefaultNavbar.vue'
import DefaultFooter from '../components/DefaultFooter.vue'

export default {
  name: 'DefaultLayout',
  components: {
    DefaultNavbar,
    DefaultFooter,
  },
}
</script>
<style src="@/assets/css/global.less" lang="less"></style>
<style src="@/assets/css/main-flex.css"></style>

<template>
  <div class="home">
    <div class="first_view">
      <div class="left col-pc-12">
        <div class="text">
          <h2>{{ $t('home.first_view.text') }}</h2>
        </div>
        <div class="button">
          <router-link :to="{ name: 'contact-us' }">
            <button type="button" class="primary-full">
              <h3>{{ $t('home.first_view.button') }}</h3>
            </button>
          </router-link>
        </div>
      </div>
    </div>
    <div class="second_view">
      <div class="right col-pc-12">
        <div class="title">
          <h1>{{ $t('home.second_view.title') }}</h1>
        </div>
        <div class="text">
          <h3>{{ $t('home.second_view.text') }}</h3>
        </div>
        <div class="button">
          <router-link :to="{ name: 'website' }">
            <button type="button" class="primary-full">
              <h3>{{ $t('home.second_view.button') }}</h3>
            </button>
          </router-link>
        </div>
      </div>
    </div>
    <div class="third_view">
      <div class="left col-pc-12">
        <div class="title">
          <h1>{{ $t('home.third_view.title') }}</h1>
        </div>
        <div class="text">
          <h3>{{ $t('home.third_view.text') }}</h3>
        </div>
        <div class="button">
          <router-link :to="{ name: 'contact-us' }">
            <button type="button" class="primary-full">
              <h3>{{ $t('home.third_view.button') }}</h3>
            </button>
          </router-link>
        </div>
      </div>
    </div>
    <div class="fourth_view contact_form_container">
      <ContactForm />
    </div>
    <!--<div class="fourth_view">
      <div class="center">
        <div class="title">
          <h1>{{ $t('home.fourth_view.title') }}</h1>
        </div>
        <div class="form col-mobile-displaynone">
          <div class="row">
            <div class="columns">
              <div class="column">
                <div class="first_name">
                  <div class="custom-input yellow">
                    <div class="input_title">
                      <p>{{ $t('first_name') }}</p>
                    </div>
                    <input class="box" type="text" :placeholder="$t('your_first_name')">
                  </div>
                </div>
                <div class="email">
                  <div class="custom-input yellow">
                    <div class="input_title">
                      <p>{{ $t('email') }}</p>
                    </div>
                    <input class="box" type="text" :placeholder="$t('your_email_address')">
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="last_name">
                  <div class="custom-input yellow">
                    <div class="input_title">
                      <p>{{ $t('last_name') }}</p>
                    </div>
                    <input class="box" type="text" :placeholder="$t('your_last_name')">
                  </div>
                </div>
                <div class="phone_number">
                  <div class="custom-input yellow">
                    <div class="input_title">
                      <p>{{ $t('phone_number') }}</p>
                    </div>
                    <input class="box" type="text" :placeholder="$t('your_phone_number')">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="column">
              <div class="message">
                <div class="textarea yellow">
                  <div class="textarea_title">
                    <p>{{ $t('message') }}</p>
                  </div>
                  <textarea class="uk-textarea yellow" cols="30" rows="10" :placeholder="$t('your_message')"></textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="column">
              <div class="button">
                <button class="yellow-full">
                  <h3>{{ $t('submit') }}</h3>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="form col-pc-displaynone">
          <div class="row">
            <div class="columns">
              <div class="column">
                <div class="first_name">
                  <div class="custom-input yellow">
                    <div class="input_title">
                      <p>{{ $t('first_name') }}</p>
                    </div>
                    <input class="box" type="text" :placeholder="$t('your_first_name')">
                  </div>
                </div>
                <div class="last_name">
                  <div class="custom-input yellow">
                    <div class="input_title">
                      <p>{{ $t('last_name') }}</p>
                    </div>
                    <input class="box" type="text" :placeholder="$t('your_last_name')">
                  </div>
                </div>
                <div class="phone_number">
                  <div class="custom-input yellow">
                    <div class="input_title">
                      <p>{{ $t('phone_number') }}</p>
                    </div>
                    <input class="box" type="text" :placeholder="$t('your_phone_number')">
                  </div>
                </div>
                <div class="email">
                  <div class="custom-input yellow">
                    <div class="input_title">
                      <p>{{ $t('email') }}</p>
                    </div>
                    <input class="box" type="text" :placeholder="$t('your_email_address')">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="column">
              <div class="message">
                <div class="textarea yellow">
                  <div class="textarea_title">
                    <p>{{ $t('message') }}</p>
                  </div>
                  <textarea class="uk-textarea yellow" cols="30" rows="10" :placeholder="$t('your_message')"></textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="column">
              <div class="button">
                <button class="yellow-full">
                  <h3>{{ $t('submit') }}</h3>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>-->
  </div>
</template>
<script>
import ContactForm from '../components/ContactForm.vue'

export default {
  name: 'HomePage',
  components: {
    ContactForm,
  },
  metaInfo() {
    const locale = this.locale
    return {
      title: this.$i18n.t('home.title'),
      titleTemplate: null,
      meta: [
        { name: 'image', content: 'https://cdn.beautec.ca/images/website/first_view_home.jpg' },
        // OpenGraph data (Most widely used)
        { property: 'og:title', content: 'Beautec - Modern Technological Web Agency - Rimouski' },
        { property: 'og:site_name', content: 'Beautec' },
        { property: 'og:type', content: 'website' },
        {
          property: 'og:url',
          content: locale === 'fr' ? 'https://beautec.ca/fr' : 'https://beautec.ca/en',
        },
        { property: 'og:image', content: 'https://cdn.beautec.ca/images/website/first_view_home.jpg' },
        {
          property: 'og:description',
          content:
            locale === 'fr'
              ? "L'Agence Web Beautec utilise des technologies modernes et rapides. Nous créons des sites internet et applications web qui font fière allure. Nous nous concentrons sur les entreprises qui veulent faire une différence pour l'environnement et internet."
              : 'Beautec uses modern and fast technologies. We create websites and web applications that look great. We focus on companies that want to make a difference for the environment and the Internet.',
        },

        // Twitter card
        {
          property: 'twitter:title',
          content:
            locale === 'fr'
              ? 'Beautec - Agence Web technologique moderne - Rimouski'
              : 'Beautec - Modern Technological Web Agency - Rimouski',
        },
        { property: 'twitter:card', content: 'summary' },
        {
          property: 'twitter:site',
          content: locale === 'fr' ? 'https://beautec.ca/fr' : 'https://beautec.ca/en',
        },
        { property: 'twitter:creator', content: '@squaretymedia' },
        { property: 'twitter:image:src', content: 'https://cdn.beautec.ca/images/website/first_view_home.jpg' },
        {
          property: 'twitter:description',
          content:
            locale === 'fr'
              ? "L'Agence Web Beautec utilise des technologies modernes et rapides. Nous créons des sites internet et applications web qui font fière allure. Nous nous concentrons sur les entreprises qui veulent faire une différence pour l'environnement et internet."
              : 'Beautec uses modern and fast technologies. We create websites and web applications that look great. We focus on companies that want to make a difference for the environment and the Internet.',
        },

        // Google / Schema.org markup:
        {
          itemprop: 'name',
          content:
            locale === 'fr'
              ? 'Beautec - Agence Web technologique moderne - Rimouski'
              : 'Beautec - Modern Technological Web Agency - Rimouski',
        },
        {
          itemprop: 'description',
          content:
            locale === 'fr'
              ? "L'Agence Web Beautec utilise des technologies modernes et rapides. Nous créons des sites internet et applications web qui font fière allure. Nous nous concentrons sur les entreprises qui veulent faire une différence pour l'environnement et internet."
              : 'Beautec uses modern and fast technologies. We create websites and web applications that look great. We focus on companies that want to make a difference for the environment and the Internet.',
        },

        { itemprop: 'image', content: 'https://cdn.beautec.ca/images/website/first_view_home.jpg' },
      ],
      link: [
        locale === 'fr'
          ? { rel: 'canonical', href: 'https://beautec.ca/fr' }
          : { rel: 'canonical', href: 'https://beautec.ca/en' },
      ],
    }
  },
}
</script>
<style scoped src="@/assets/css/home.less" lang="less"></style>

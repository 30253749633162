<template>
  <div class="odeyr project">
    <div class="first_view col-pc-10 col-mobile-10 col-margin-auto">
      <div class="flexbox col-f">
        <div class="left col-all-4">
          <router-link class="col-f" :to="{ name: 'portfolio', query: { searchType: $route.query.searchType } }">
            <i class="fas fa-long-arrow-alt-left" />
            <p>{{ $t('return') }}</p>
          </router-link>
        </div>
        <div class="middle col-all-4">
          <h2>Odeyr</h2>
        </div>
        <div class="right col-all-4" />
      </div>
    </div>
    <div class="second_view col-pc-10 col-mobile-10 col-margin-auto">
      <div class="image col-pc-6 col-mobile-12 col-margin-auto" uk-lightbox="animation: fade">
        <a href="//cdn.beautec.ca/images/portfolio/odeyr/odeyr-screenshot.png" alt="odeyr website">
          <img
            src="//cdn.beautec.ca/images/portfolio/odeyr/odeyr-screenshot.png"
            uk-lightbox="animation: fade"
            alt="odeyr website"
          />
        </a>
      </div>
      <div class="needs col-pc-10">
        <div class="title">
          <h3>{{ $t('needs') }}</h3>
        </div>
        <div class="text">
          <ul>
            <li>
              <p>{{ $t('odeyr.second_view.needs.first') }}</p>
            </li>
            <li>
              <p>{{ $t('odeyr.second_view.needs.second') }}</p>
            </li>
            <li>
              <p>{{ $t('odeyr.second_view.needs.third') }}</p>
            </li>
          </ul>
        </div>
      </div>
      <div class="solutions col-pc-10 col-margin-auto">
        <div class="title">
          <h3>{{ $t('solutions') }}</h3>
        </div>
        <div class="text">
          <ul>
            <li>
              <p>{{ $t('odeyr.second_view.solutions.first') }}</p>
            </li>
            <li>
              <p>{{ $t('odeyr.second_view.solutions.second') }}</p>
            </li>
            <li>
              <p>{{ $t('odeyr.second_view.solutions.third') }}</p>
            </li>
          </ul>
        </div>
      </div>
      <div class="results col-pc-10 col-margin-auto">
        <div class="title">
          <h3>{{ $t('results') }}</h3>
        </div>
        <div class="text">
          <ul>
            <li>
              <p>{{ $t('odeyr.second_view.results.first') }}</p>
            </li>
            <li>
              <p>{{ $t('odeyr.second_view.results.second') }}</p>
            </li>
          </ul>
        </div>
      </div>
      <div class="visit">
        <a href="//odeyr.org" target="blank" class="col-all-12">
          <button type="button" class="secondary-full col-all-12">
            <h3>{{ $t('visit') }}</h3>
          </button>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SrpqWebsite',
  metaInfo() {
    const locale = this.locale
    return {
      title: [
        locale === 'fr'
          ? 'Beautec - Agence Web technologique moderne - Rimouski'
          : 'Beautec - Modern Technological Web Agency - Rimouski',
      ],
      titleTemplate: null,
      meta: [
        {
          name: 'description',
          content:
            locale === 'fr'
              ? "L'Agence Web Beautec utilise des technologies modernes et rapides. Nous créons des sites internet et applications web qui font fière allure. Nous nous concentrons sur les entreprises qui veulent faire une différence pour l'environnement et internet."
              : 'Beautec uses modern and fast technologies. We create websites and web applications that look great. We focus on companies that want to make a difference for the environment and the Internet.',
        },
        { name: 'image', content: 'https://cdn.beautec.ca/images/portfolio/srpq/srpq-screenshot.png' },
        // OpenGraph data (Most widely used)
        {
          property: 'og:title',
          content:
            locale === 'fr'
              ? 'Beautec - Agence Web technologique moderne - Rimouski'
              : 'Beautec - Modern Technological Web Agency - Rimouski',
        },
        { property: 'og:site_name', content: 'Beautec' },
        { property: 'og:type', content: 'website' },
        {
          property: 'og:url',
          content: locale === 'fr' ? 'https://beautec.ca/fr/portfolio/srpq' : 'https://beautec.ca/en/portfolio/srpq',
        },
        { property: 'og:image', content: 'https://cdn.beautec.ca/images/portfolio/srpq/srpq-screenshot.png' },
        {
          property: 'og:description',
          content:
            locale === 'fr'
              ? "L'Agence Web Beautec utilise des technologies modernes et rapides. Nous créons des sites internet et applications web qui font fière allure. Nous nous concentrons sur les entreprises qui veulent faire une différence pour l'environnement et internet."
              : 'Beautec uses modern and fast technologies. We create websites and web applications that look great. We focus on companies that want to make a difference for the environment and the Internet.',
        },

        // Twitter card
        {
          property: 'twitter:title',
          content:
            locale === 'fr'
              ? 'Beautec - Agence Web technologique moderne - Rimouski'
              : 'Beautec - Modern Technological Web Agency - Rimouski',
        },
        { property: 'twitter:card', content: 'summary' },
        {
          property: 'twitter:site',
          content: locale === 'fr' ? 'https://beautec.ca/fr/portfolio/srpq' : 'https://beautec.ca/en/portfolio/srpq',
        },
        { property: 'twitter:creator', content: '@squaretymedia' },
        {
          property: 'twitter:image:src',
          content: 'https://cdn.beautec.ca/images/portfolio/srpq/srpq-screenshot.png',
        },
        {
          property: 'twitter:description',
          content:
            locale === 'fr'
              ? "L'Agence Web Beautec utilise des technologies modernes et rapides. Nous créons des sites internet et applications web qui font fière allure. Nous nous concentrons sur les entreprises qui veulent faire une différence pour l'environnement et internet."
              : 'Beautec uses modern and fast technologies. We create websites and web applications that look great. We focus on companies that want to make a difference for the environment and the Internet.',
        },

        // Google / Schema.org markup:
        {
          itemprop: 'name',
          content:
            locale === 'fr'
              ? 'Beautec - Agence Web technologique moderne - Rimouski'
              : 'Beautec - Modern Technological Web Agency - Rimouski',
        },
        {
          itemprop: 'description',
          content:
            locale === 'fr'
              ? "L'Agence Web Beautec utilise des technologies modernes et rapides. Nous créons des sites internet et applications web qui font fière allure. Nous nous concentrons sur les entreprises qui veulent faire une différence pour l'environnement et internet."
              : 'Beautec uses modern and fast technologies. We create websites and web applications that look great. We focus on companies that want to make a difference for the environment and the Internet.',
        },
        { itemprop: 'image', content: 'https://cdn.beautec.ca/images/portfolio/srpq/srpq-screenshot.png' },
      ],
      link: [
        locale === 'fr'
          ? { rel: 'canonical', href: 'https://beautec.ca/fr/portfolio/srpq' }
          : { rel: 'canonical', href: 'https://beautec.ca/en/portfolio/srpq' },
      ],
    }
  },
}
</script>
<style scoped src="@/assets/css/portfolio.css"></style>

<template>
  <div class="contact-form">
    <div class="center">
      <div class="title">
        <h1>{{ $t('home.fourth_view.title') }}</h1>
      </div>
      <form class="form" @submit.prevent="sendEmail">
        <div class="form_container">
          <div class="row">
            <div class="columns">
              <div class="column">
                <div class="first_name">
                  <div class="custom-input yellow">
                    <div class="input_title">
                      <p>{{ $t('first_name') }}</p>
                    </div>
                    <label for="first_name" />
                    <input class="box" type="text" name="first_name" :placeholder="$t('your_first_name')" required />
                  </div>
                </div>
                <div class="email">
                  <div class="custom-input yellow">
                    <div class="input_title">
                      <p>{{ $t('email') }}</p>
                    </div>
                    <label for="email_address" />
                    <input
                      class="box"
                      type="email"
                      name="email_address"
                      :placeholder="$t('your_email_address')"
                      required
                    />
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="last_name">
                  <div class="custom-input yellow">
                    <div class="input_title">
                      <p>{{ $t('last_name') }}</p>
                    </div>
                    <label for="last_name" />
                    <input class="box" type="text" name="last_name" :placeholder="$t('your_last_name')" required />
                  </div>
                </div>
                <div class="phone_number">
                  <div class="custom-input yellow">
                    <div class="input_title">
                      <p>{{ $t('phone_number') }}</p>
                    </div>
                    <label for="phone_number" />
                    <input
                      class="box"
                      type="text"
                      name="phone_number"
                      :placeholder="$t('your_phone_number')"
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="column">
              <div class="message">
                <div class="textarea yellow">
                  <div class="textarea_title">
                    <p>{{ $t('message') }}</p>
                  </div>
                  <label for="message" />
                  <textarea
                    class="uk-textarea yellow"
                    name="message"
                    cols="30"
                    rows="10"
                    :placeholder="$t('your_message')"
                    style="resize: none"
                    required
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="column">
              <div class="captcha">
                <vue-recaptcha sitekey="6LfYpMgUAAAAACUBxXtkRDodCuCto2aZFJ0DE91j" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="column">
              <div class="button">
                <button class="yellow-full" type="submit" @click="isClicked = true">
                  <h3 v-if="senderResult !== 200 && senderResult !== 400">
                    {{ $t('submit') }}
                  </h3>
                  <h3 v-if="senderResult === 200" style="color: #17b34d">
                    {{ $t('sended') }} <i class="fas fa-check-circle" style="color: #17b34d" />
                  </h3>
                  <h3 v-if="senderResult === 400" style="color: #e74c3c">
                    {{ $t('error_occured') }} <i class="fas fa-exclamation-triangle" style="color: #e74c3c" />
                  </h3>
                </button>
              </div>
            </div>
          </div>
        </div>
        <!--<div class="form_container col-pc-displaynone">
          <div class="row">
            <div class="columns">
              <div class="column">
                <div class="first_name">
                  <div class="custom-input yellow">
                    <div class="input_title">
                      <p>{{ $t('first_name') }}</p>
                    </div>
                    <input class="box" type="text" name="first_name" :placeholder="$t('your_first_name')" required>
                  </div>
                </div>
                <div class="last_name">
                  <div class="custom-input yellow">
                    <div class="input_title">
                      <p>{{ $t('last_name') }}</p>
                    </div>
                    <input class="box" type="text" name="last_name" :placeholder="$t('your_last_name')" required>
                  </div>
                </div>
                <div class="phone_number">
                  <div class="custom-input yellow">
                    <div class="input_title">
                      <p>{{ $t('phone_number') }}</p>
                    </div>
                    <input class="box" type="text" name="phone_number" :placeholder="$t('your_phone_number')" required>
                  </div>
                </div>
                <div class="email">
                  <div class="custom-input yellow">
                    <div class="input_title">
                      <p>{{ $t('email') }}</p>
                    </div>
                    <input class="box" type="text" name="email_address" :placeholder="$t('your_email_address')" required>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="column">
              <div class="message">
                <div class="textarea yellow">
                  <div class="textarea_title">
                    <p>{{ $t('message') }}</p>
                  </div>
                  <textarea class="uk-textarea yellow" cols="30" rows="10" name="message" :placeholder="$t('your_message')" required></textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="column">
              <div class="captcha">
                <vue-recaptcha sitekey="6LfYpMgUAAAAACUBxXtkRDodCuCto2aZFJ0DE91j">
                </vue-recaptcha>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="column">
              <div class="button">
                <button class="yellow-full" type="submit">
                  <h3>{{ $t('submit') }}</h3>
                </button>
              </div>
            </div>
          </div>
        </div>-->
      </form>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import emailjs from 'emailjs-com'
import VueRecaptcha from 'vue-recaptcha'

export default {
  name: 'ContactForm',
  components: {
    VueRecaptcha,
  },
  data() {
    return {
      senderResult: '',
    }
  },
  methods: {
    sendEmail(e) {
      emailjs.sendForm('default_service', 'template_k3qPFH83', e.target, 'user_6012XVO48e8uX68rBIMuN').then(
        (result) => {
          this.senderResult = result.status
          console.log('SUCCESS!', result.status, result.text)
        },
        (error) => {
          console.log('FAILED...', error)
        }
      )
    },
  },
}
</script>
<style scoped src="@/assets/css/components/contact-form.less" lang="less"></style>

import Vue from 'vue'
import Router from 'vue-router'
import LangTemplate from './views/LangTemplate.vue'

import HomePage from './views/Home.vue'
import Terms from './views/legal/Terms.vue'
import WebApplication from './views/products/WebApplication.vue'
import Website from './views/products/Website.vue'
// import WebHosting from './views/products/WebHosting.vue'
import Portfolio from './views/Portfolio.vue'
import SrpqWebsite from './views/projects/Srpq.vue'
import OptezbeauteWebsite from './views/projects/Optezbeaute.vue'
import OdeyrWebsite from './views/projects/Odeyr.vue'
import TermsOfSale from './views/TermsOfSale.vue'
import ContactUs from './views/ContactUs.vue'
// import TranslationService.js Script
import TranslationService from './TranslationService'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: '/',
  routes: [
    {
      path: '/:lang',
      component: LangTemplate,
      beforeEnter: TranslationService.routeMiddleware,
      children: [
        {
          path: '',
          name: 'home',
          alias: 'home',
          component: HomePage,
        },
        {
          name: 'terms',
          path: 'legal/terms',
          component: Terms,
        },
        {
          name: 'web-application',
          path: 'products/web-application',
          component: WebApplication,
        },
        {
          name: 'website',
          path: 'products/website',
          component: Website,
        },
        /*
        {
          name: 'web-hosting',
          path: '/:lang/products/web-hosting',
          component: WebHosting
        },
        */
        {
          name: 'portfolio',
          path: 'portfolio',
          component: Portfolio,
        },
        {
          name: 'srpq',
          path: 'porfolio/customers/srpq',
          component: SrpqWebsite,
        },
        {
          name: 'optez_beaute',
          path: 'portfolio/customers/optez_beaute',
          component: OptezbeauteWebsite,
        },
        {
          name: 'odeyr',
          path: 'portfolio/customers/odeyr',
          component: OdeyrWebsite,
        },
        {
          name: 'terms-of-sale',
          alias: 'cgv',
          path: 'terms-of-sale',
          component: TermsOfSale,
        },
        {
          name: 'contact-us',
          path: 'contact-us',
          component: ContactUs,
        },
      ],
    },
  ],
})

export default router

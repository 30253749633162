import Vue from 'vue'
import VueMeta from 'vue-meta'
import VueSweetalert2 from 'vue-sweetalert2'
import moment from 'moment'
import ToggleButton from 'vue-js-toggle-button'
import VModal from 'vue-js-modal'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'

import Default from './layouts/DefaultLayout.vue'

Vue.component('DefaultLayout', Default)

Vue.config.productionTip = false

Vue.prototype.moment = moment

Vue.use(ToggleButton)
Vue.use(VModal)
Vue.use(VueSweetalert2)
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true,
})

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app')

<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>
<script>
import DefaultLayout from './layouts/DefaultLayout.vue'

export default {
  computed: {
    layout() {
      return DefaultLayout
    },
  },
  metaInfo() {
    const locale = this.locale
    return {
      title: this.$i18n.t('squarety_media_main_title'),
      titleTemplate: null,
      meta: [
        {
          name: 'description',
          content:
            "L'Agence Web Beautec utilise des technologies modernes et rapides. Nous créons des sites internet et applications web qui font fière allure. Nous nous concentrons sur les entreprises qui veulent faire une différence pour l'environnement et internet.",
          // : `Beautec uses modern and fast technologies. We create websites and web applications that look great. We focus on companies that want to make a difference for the environment and the Internet.`
        },
        { name: 'image', content: 'https://cdn.squaretymedia.com/images/website/first_view_home.jpg' },
        // OpenGraph data (Most widely used)
        { property: 'og:title', content: 'Beautec - Agence Web technologique moderne - Rimouski' },
        { property: 'og:site_name', content: 'Beautec' },
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: 'https://squaretymedia.com/fr' },
        { property: 'og:image', content: 'https://cdn.squaretymedia.com/images/website/first_view_home.jpg' },
        {
          property: 'og:description',
          content:
            "L'Agence Web Beautec utilise des technologies modernes et rapides. Nous créons des sites internet et applications web qui font fière allure. Nous nous concentrons sur les entreprises qui veulent faire une différence pour l'environnement et internet.",
          // : `Beautec uses modern and fast technologies. We create websites and web applications that look great. We focus on companies that want to make a difference for the environment and the Internet.`
        },

        // Twitter card
        {
          property: 'twitter:title',
          content: 'Beautec - Agence Web technologique moderne - Rimouski',
          // : `Beautec - Modern Technological Web Agency - Rimouski`
        },
        { property: 'twitter:card', content: 'summary' },
        {
          property: 'twitter:site',
          content: 'https://squaretymedia.com/fr',
        },
        { property: 'twitter:creator', content: '@squaretymedia' },
        { property: 'twitter:image:src', content: 'https://cdn.squaretymedia.com/images/website/first_view_home.jpg' },
        {
          property: 'twitter:description',
          content:
            "L'Agence Web Beautec utilise des technologies modernes et rapides. Nous créons des sites internet et applications web qui font fière allure. Nous nous concentrons sur les entreprises qui veulent faire une différence pour l'environnement et internet.",
          // : `Beautec uses modern and fast technologies. We create websites and web applications that look great. We focus on companies that want to make a difference for the environment and the Internet.`
        },

        // Google / Schema.org markup:
        {
          itemprop: 'name',
          content: 'Beautec - Agence Web technologique moderne - Rimouski',
          // : `Beautec - Modern Technological Web Agency - Rimouski`
        },
        {
          itemprop: 'description',
          content:
            "L'Agence Web Beautec utilise des technologies modernes et rapides. Nous créons des sites internet et applications web qui font fière allure. Nous nous concentrons sur les entreprises qui veulent faire une différence pour l'environnement et internet.",
          // : `Beautec uses modern and fast technologies. We create websites and web applications that look great. We focus on companies that want to make a difference for the environment and the Internet.`
        },
        { itemprop: 'image', content: 'https://cdn.squaretymedia.com/images/website/first_view_home.jpg' },
      ],
      link: [
        locale === 'fr'
          ? { rel: 'canonical', href: 'https://squaretymedia.com/fr' }
          : { rel: 'canonical', href: 'https://squaretymedia.com/' },
      ],
    }
  },
}
</script>
<style src="@/assets/css/global.less" lang="less"></style>
<style src="@/assets/css/main-flex.css"></style>
<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>

<template>
  <div class="optezbeaute project">
    <div class="first_view col-pc-10 col-mobile-10 col-margin-auto">
      <div class="flexbox col-f">
        <div class="left col-all-4">
          <router-link class="col-f" :to="{ name: 'portfolio', query: { searchType: $route.query.searchType } }">
            <i class="fas fa-long-arrow-alt-left" />
            <p>{{ $t('return') }}</p>
          </router-link>
        </div>
        <div class="middle col-all-4">
          <h2>Optez-beauté</h2>
        </div>
        <div class="right col-all-4" />
      </div>
    </div>
    <div class="second_view col-pc-10 col-mobile-10 col-margin-auto">
      <div class="image col-pc-6 col-mobile-12 col-margin-auto" uk-lightbox="animation: fade">
        <a
          href="//cdn.beautec.ca/images/portfolio/optez-beaute/screenshot-optezbeaute.ca-2020.03.26-15_20_47.png"
          alt="optez-beaute website"
        >
          <img
            src="//cdn.beautec.ca/images/portfolio/optez-beaute/screenshot-optezbeaute.ca-2020.03.26-15_20_47.png"
            uk-lightbox="animation: fade"
            alt="optez-beaute website"
          />
        </a>
      </div>
      <div class="needs col-pc-10">
        <div class="title">
          <h3>{{ $t('needs') }}</h3>
        </div>
        <div class="text">
          <ul>
            <li>
              <p>{{ $t('optez_beaute.second_view.needs.first') }}</p>
            </li>
            <li>
              <p>{{ $t('optez_beaute.second_view.needs.second') }}</p>
            </li>
            <li>
              <p>{{ $t('optez_beaute.second_view.needs.third') }}</p>
            </li>
          </ul>
        </div>
      </div>
      <div class="solutions col-pc-10 col-margin-auto">
        <div class="title">
          <h3>{{ $t('solutions') }}</h3>
        </div>
        <div class="text">
          <ul>
            <li>
              <p>{{ $t('optez_beaute.second_view.solutions.first') }}</p>
            </li>
            <li>
              <p>{{ $t('optez_beaute.second_view.solutions.second') }}</p>
            </li>
            <li>
              <p>{{ $t('optez_beaute.second_view.solutions.third') }}</p>
            </li>
          </ul>
        </div>
      </div>
      <div class="results col-pc-10 col-margin-auto">
        <div class="title">
          <h3>{{ $t('results') }}</h3>
        </div>
        <div class="text">
          <ul>
            <li>
              <p>{{ $t('optez_beaute.second_view.results.first') }}</p>
            </li>
            <!--<li>
              <p>{{ $t('optez_beaute.second_view.results.second') }}</p>
            </li>-->
          </ul>
        </div>
      </div>
      <div class="visit">
        <a href="//optezbeaute.ca" target="blank" class="col-all-12">
          <button type="button" class="secondary-full col-all-12">
            <h3>{{ $t('visit') }}</h3>
          </button>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'OptezBeaute',
  metaInfo() {
    const locale = this.locale
    return {
      title: this.$i18n.t('optez_beaute.title'),
      titleTemplate: null,
      meta: [
        {
          name: 'description',
          content:
            locale === 'fr'
              ? 'Le site web d’Optez Beauté est un site web de commerce électronique qui permet aux clients de commander des produits de beauté en ligne. Le site web est entièrement personnalisé et utilise un système de gestion de contenu (CMS) pour gérer les produits, les commandes et les clients.'
              : 'The Optez Beauté website is an e-commerce website that allows customers to order beauty products online. The website is fully customized and uses a content management system (CMS) to manage products, orders and customers.',
        },
        {
          name: 'image',
          content: '//cdn.beautec.ca/images/portfolio/optez-beaute/screenshot-optezbeaute.ca-2020.03.26-15_20_47.png',
        },
        // OpenGraph data (Most widely used)
        {
          property: 'og:title',
          content:
            locale === 'fr'
              ? 'Beautec - Agence Web technologique moderne - Rimouski'
              : 'Beautec - Modern Technological Web Agency - Rimouski',
        },
        { property: 'og:site_name', content: 'Beautec' },
        { property: 'og:type', content: 'website' },
        {
          property: 'og:url',
          content:
            locale === 'fr'
              ? 'https://beautec.ca/fr/portfolio/optez-beaute'
              : 'https://beautec.ca/en/portfolio/optez-beaute',
        },
        {
          property: 'og:image',
          content:
            '//cdn.squaretymedia.com/images/portfolio/optez-beaute/screenshot-optezbeaute.ca-2020.03.26-15_20_47.png',
        },
        {
          property: 'og:description',
          content:
            locale === 'fr'
              ? 'Le site web d’Optez Beauté est un site web de commerce électronique qui permet aux clients de commander des produits de beauté en ligne. Le site web est entièrement personnalisé et utilise un système de gestion de contenu (CMS) pour gérer les produits, les commandes et les clients.'
              : 'The Optez Beauté website is an e-commerce website that allows customers to order beauty products online. The website is fully customized and uses a content management system (CMS) to manage products, orders and customers.',
        },

        // Twitter card
        {
          property: 'twitter:title',
          content:
            locale === 'fr'
              ? 'Beautec - Agence Web technologique moderne - Rimouski'
              : 'Beautec - Modern Technological Web Agency - Rimouski',
        },
        { property: 'twitter:card', content: 'summary' },
        {
          property: 'twitter:site',
          content:
            locale === 'fr'
              ? 'https://beautec.ca/fr/portfolio/optez-beaute'
              : 'https://beautec.ca/en/portfolio/optez-beaute',
        },
        { property: 'twitter:creator', content: '@squaretymedia' },
        {
          property: 'twitter:image:src',
          content:
            '//cdn.squaretymedia.com/images/portfolio/optez-beaute/screenshot-optezbeaute.ca-2020.03.26-15_20_47.png',
        },
        {
          property: 'twitter:description',
          content:
            locale === 'fr'
              ? 'Le site web d’Optez Beauté est un site web de commerce électronique qui permet aux clients de commander des produits de beauté en ligne. Le site web est entièrement personnalisé et utilise un système de gestion de contenu (CMS) pour gérer les produits, les commandes et les clients.'
              : 'The Optez Beauté website is an e-commerce website that allows customers to order beauty products online. The website is fully customized and uses a content management system (CMS) to manage products, orders and customers.',
        },

        // Google / Schema.org markup:
        {
          itemprop: 'name',
          content:
            locale === 'fr'
              ? 'Beautec - Agence Web technologique moderne - Rimouski'
              : 'Beautec - Modern Technological Web Agency - Rimouski',
        },
        {
          itemprop: 'description',
          content:
            locale === 'fr'
              ? 'Le site web d’Optez Beauté est un site web de commerce électronique qui permet aux clients de commander des produits de beauté en ligne. Le site web est entièrement personnalisé et utilise un système de gestion de contenu (CMS) pour gérer les produits, les commandes et les clients.'
              : 'The Optez Beauté website is an e-commerce website that allows customers to order beauty products online. The website is fully customized and uses a content management system (CMS) to manage products, orders and customers.',
        },
        {
          itemprop: 'image',
          content:
            '//cdn.squaretymedia.com/images/portfolio/optez-beaute/screenshot-optezbeaute.ca-2020.03.26-15_20_47.png',
        },
      ],
      link: [
        locale === 'fr'
          ? { rel: 'canonical', href: 'https://beautec.ca/fr/portfolio/optez-beaute' }
          : { rel: 'canonical', href: 'https://beautec.ca/en/portfolio/optez-beaute' },
      ],
    }
  },
}
</script>
<style scoped src="@/assets/css/portfolio.css"></style>

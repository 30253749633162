<template>
  <div class="pp">
    <div v-if="lang == 'fr'" id="fr">
      <div class="_s1 col-pc-12">
        <div class="flexbox">
          <div class="_content col-pc-10">
            <div class="_title">
              <h3>Politique de confidentialité</h3>
            </div>
            <div class="_text-container">
              <div class="legal-application">
                <ul class="last-update">
                  <li class="text" style="margin-right: 5px">
                    Dernière modification: &nbsp;
                  </li>
                  <li class="date">
                    <p>15 Décembre 2019</p>
                  </li>
                </ul>
                <ul class="effective">
                  <li class="text" style="margin-right: 5px">
                    Entrée en vigueur: &nbsp;
                  </li>
                  <li class="date">
                    <p>18 Décembre 2019</p>
                  </li>
                </ul>
              </div>
              <div class="legal-content">
                <div class="__official-lang-application">
                  <i>
                    Cette traduction est fournie uniquement pour des raisons de commodité et la version anglaise
                    prévaudra en cas de divergence.
                  </i>
                </div>
                <p>
                  Votre vie privée est importante pour nous. Squarety Média a pour politique de respecter votre vie
                  privée en ce qui concerne toute information que nous pouvons recueillir auprès de vous sur notre site
                  Web, <a href="https://squaretymedia.com">https://squaretymedia.com</a>, et sur tout autres sites que
                  nous possédons et exploitons.
                </p>
                <h3>1. Utilisation</h3>
                <p>
                  Nous ne demandons des renseignements personnels que lorsque nous en avons vraiment besoin pour vous
                  offrir un service. Nous les recueillons par des moyens justes et légaux, à votre connaissance et avec
                  votre consentement. Nous vous informons également des raisons pour lesquelles nous recueillons ces
                  renseignements et de la façon dont ils seront utilisés.
                </p>
                <p>
                  Nous ne conservons les renseignements recueillis que le temps nécessaire pour vous fournir le service
                  demandé. Les données que nous stockons seront protégées par des moyens commercialement acceptables
                  pour prévenir la perte et le vol, ainsi que l’accès non autorisé, la divulgation, la copie,
                  l’utilisation ou la modification.
                </p>
                <p>
                  Nous ne communiquons pas de renseignements d’identification personnels au public ou à des tiers, sauf
                  lorsque la loi l’exige.
                </p>
                <h3>2. Bases légales du RGPD pour le traitement des données personnelles des sujets de données</h3>
                <p>
                  En vertu du RGPD, Squarety Média ne peut traiter les données personnelles d’un sujet de données que si
                  Squarety Média dispose d’au moins une des six bases légales de traitement des données personnelles.
                  Actuellement en vertu de cette version de la politique de confidentialité, Squarety Média compte sur
                  au moins une des bases juridiques suivantes pour traiter les données personnelles en vertu du RGPD :
                </p>
                <div class="__article">
                  <p>
                    - Afin d’exécuter un ou plusieurs accords juridiques (contrats) avec vous,<br />- Avec votre
                    consentement, et<br />- Conformément à un Intérêt légitime, dans lequel Squarety Média considère
                    l’objectif qu’elle a pour le traitement de vos données personnelles, si le traitement est nécessaire
                    pour cet objectif, et si vos intérêts personnels en tant que sujet de données l’emportent sur le but
                    de Squarety Média dans le traitement de vos données personnelles.
                  </p>
                </div>
                <p>
                  (À titre de rappel, si vous n’êtes pas un sujet de données fournissant vos données personnelles à
                  Squarety Média à partir de l’Union européenne, le RGPD ne s’applique pas à vous.)
                </p>

                <h3>3. Hyperliens de tiers</h3>
                <p>
                  Notre site Web peut être relié à des sites externes qui ne sont pas exploités par nous. Sachez que
                  nous ne contrôlons pas le contenu et les pratiques de ces sites et que nous ne pouvons pas accepter la
                  responsabilité de leurs politiques de confidentialité respectives.
                </p>

                <h3>4. Squarety Média respecte votre droit de refuser</h3>
                <p>
                  Vous êtes libre de refuser notre demande de renseignements personnels, en sachant que nous pourrions
                  être incapables de vous fournir certains de vos services souhaités.
                </p>

                <h3>5. Cookies</h3>
                <p>
                  Nous et nos partenaires utilisons des cookies et des technologies similaires sur nos sites Web. Pour
                  plus d’informations, consultez le paragraphe Cookies dans nos Conditions de Service.
                </p>
                <p>
                  Nous utilisons certains cookies, tels que décrits dans nos Conditions de Service et ici dans notre
                  Politique de Confidentialité, que vous acceptez lorsque vous utilisez nos sites et, dans le cas de
                  certains cookies, pour des intérêts légitimes de livraison et d’optimisation de nos services (où le
                  cookie fournit des fonctionnalités essentielles). Les cookies sont de petits morceaux de données que
                  nous stockons sur l’appareil que vous utilisez pour accéder à nos services afin que nous puissions
                  reconnaître les utilisateurs récurrents.Chaque cookie expire après un certain temps, selon ce à quoi
                  nous l’utilisons. Nous utilisons des cookies et des technologies similaires pour plusieurs raisons:
                </p>
                <div class="__article">
                  <h4>Pour rendre notre site plus facile à utiliser.</h4>
                  <p>
                    Si vous utilisez la fonction « Rester connecté » lorsque vous vous connectez à votre compte, nous
                    stockons votre adresse courriel et votre mot de passe comme jeton dans un cookie pour que vous
                    puissiez vous connecter plus rapidement chaque fois que vous retournez sur Squarety Média.
                  </p>

                  <h4>Pour des raisons de sécurité</h4>
                  <p>
                    Nous utilisons des cookies pour authentifier votre identité et confirmer si vous êtes actuellement
                    connecté à Squarety Média ou pour déterminer si un incident vous touche.
                  </p>
                </div>

                <h3>6. Sécurité des données</h3>
                <p>
                  Nous utilisons des mesures de sécurité commercialement raisonnables pour protéger vos renseignements;
                </p>
                <p>
                  cependant, aucun système n’est impénétrable. Si vous créez un compte sur les Services, vous êtes
                  responsable de protéger la sécurité de votre compte, son contenu et toutes les activités qui se
                  produisent dans le compte ou en lien avec les Services. Vous devez immédiatement aviser Squarety Média
                  de toute utilisation non autorisée de votre compte ou de toute autre infraction à la sécurité en nous
                  envoyant un courriel à <a href="mailto:contact@squaretymedia.com">contact@squaretymedia.com</a>.
                </p>

                <h3>7. Vous avez des questions?</h3>
                <p>
                  Votre utilisation continue de notre site Web, de notre application Web et de notre interface de
                  programmation (API) sera considérée comme une acceptation de nos pratiques en matière de
                  confidentialité et de renseignements personnels. Si vous avez des questions sur la façon dont nous
                  traitons les données utilisateur et les informations personnelles, n’hésitez pas à
                  <router-link :to="{ name: 'contact-us' }">
                    nous contacter
                  </router-link>.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="lang == 'en'" id="en">
      <div class="_s1 col-pc-12">
        <div class="flexbox">
          <div class="_content col-pc-10">
            <div class="_title">
              <h3>Privacy Policy</h3>
            </div>
            <div class="_text-container">
              <div class="legal-application">
                <ul class="last-update">
                  <li class="text" style="margin-right: 5px">
                    Last update: &nbsp;
                  </li>
                  <li class="date">
                    <p>15 December 2019</p>
                  </li>
                </ul>
                <ul class="effective">
                  <li class="text" style="margin-right: 5px">
                    Effective: &nbsp;
                  </li>
                  <li class="date">
                    <p>18 December 2019</p>
                  </li>
                </ul>
              </div>
              <div class="legal-content">
                <div class="__official-lang-application">
                  <!--<i>This translation is provided for convenience only and the English language version will control in the event of any discrepancies.</i>-->
                </div>
                <p>
                  Your privacy is important to us. It is Squarety Média's policy to respect your privacy regarding any
                  information we may collect from you across our website,
                  <a href="https://squaretymedia.com">https://squaretymedia.com</a>, and other sites and API we own and
                  operate.
                </p>
                <h3>1. Usage</h3>
                <p>
                  We only ask for personal information when we truly need it to provide a service to you. We collect it
                  by fair and lawful means, with your knowledge and consent. We also let you know why we’re collecting
                  it and how it will be used.
                </p>
                <p>
                  We only retain collected information for as long as necessary to provide you with your requested
                  service. What data we store, we’ll protect within commercially acceptable means to prevent loss and
                  theft, as well as unauthorised access, disclosure, copying, use or modification.
                </p>
                <p>
                  We don’t share any personally identifying information publicly or with third-parties, except when
                  required to by law.
                </p>
                <h3>2. GDPR Lawful Bases for Processing Personal Data of Data Subjects</h3>
                <p>
                  Under GDPR, Squarety Média may only Process the Personal Data of a Data Subject lawfully if Squarety
                  Média has at least one of six lawful bases for Processing the Personal Data. Currently under this
                  version of the privacy policy, Squarety Média relies on at least one of the following legal bases to
                  process Personal Data under GDPR:
                </p>
                <div class="__article">
                  <p>
                    - In order to perform pursuant to one or more legal agreements (contracts) with you,<br />- With
                    your consent, and<br />- Pursuant to a Legitimate Interest, in which Squarety Média considers the
                    purpose it has for Processing your Personal Data, whether the Processing is necessary for that
                    Purpose, and whether your own personal interests as a Data Subject outweigh Squarety Média’s purpose
                    in Processing your Personal Data.
                  </p>
                </div>
                <p>
                  (As a reminder, if you are not a Data Subject providing your Personal Data to Squarety Média from
                  within the European Union, GDPR does not apply to you.)
                </p>

                <h3>3. Third-Parties Links</h3>
                <p>
                  Our website may link to external sites that are not operated by us. Please be aware that we have no
                  control over the content and practices of these sites, and cannot accept responsibility or liability
                  for their respective privacy policies.
                </p>

                <h3>4. Squarety Média respect your right to refuse</h3>
                <p>
                  You are free to refuse our request for your personal information, with the understanding that we may
                  be unable to provide you with some of your desired services.
                </p>

                <h3>5. Cookies</h3>
                <p>
                  We and our partners use cookies and similar technologies on our websites. For more information see the
                  Cookies paragraph in our Terms of Service.
                </p>
                <p>
                  We use certain cookies, as described in our Terms of Service and here in our Privacy Policy, that you
                  agree to when you use our sites and, in the case of some cookies, for legitimate interests of
                  delivering and optimizing our services (where the cookie delivers essential functionality). Cookies
                  are small bits of data we store on the device you use to access our services so we can recognize
                  repeat users. Each cookie expires after a certain period of time, depending on what we use it for. We
                  use cookies and similar technologies for several reasons:
                </p>
                <div class="__article">
                  <h4>To make our site easier to use.</h4>
                  <p>
                    If you use the “Remember me” feature when you sign into your account, we store your email address
                    and your password as a token in a cookie to make it quicker for you to sign in whenever you return
                    to Squarety Média.
                  </p>

                  <h4>For security reasons.</h4>
                  <p>
                    We use cookies to authenticate your identity and confirm whether you are currently logged into
                    Squarety Média or determine if an incident impacts you.
                  </p>
                </div>

                <h3>6. Data Security</h3>
                <p>We employ commercially reasonable security measures to protect your information;</p>
                <p>
                  however, no system is impenetrable. If you create an account on the Services, you are responsible for
                  protecting the security of your account, its content, and all activities that occur under the account
                  or in connection with the Services. You must immediately notify Squarety Média of any unauthorized
                  uses of your account or any other breaches of security by emailing us at
                  <a href="mailto:contact@squaretymedia.com">contact@squaretymedia.com</a>.
                </p>

                <h3>7. Any questions?</h3>
                <p>
                  Your continued use of our website will be regarded as acceptance of our practices around privacy and
                  personal information. If you have any questions about how we handle user data and personal
                  information, feel free to <router-link :to="{ name: 'contact-us' }">
                    contact us
                  </router-link>.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PrivacyPolicy',
  computed: {
    lang() {
      return this.$route.params.lang
    },
  },
}
</script>
<style scoped src="@/assets/css/legal.less" lang="less"></style>

<style>
a {
  transition: all 0.3s;
}
a:hover {
  color: #f2684a;
  transition: all 0.3s;
}
</style>
